import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "gatsby-theme-wirehead-bulma/src/layout"
import SEO from "gatsby-theme-wirehead-bulma/src/seo"
import PostLink from "../components/post-link"

const IndexPage = ({
  data: {
    allSitePage: { edges },
  },
  pageContext,
  location,
}) => {
  const Posts = edges.map(edge => {
    return <PostLink key={edge.node.path} post={edge} />
  })

  return (<Layout >
    <SEO title={pageContext.title}  pathname={location.pathname} />
    <h1 className="title is-3">Recent updates:</h1>
    <section className="section">
    <div className="content">
    {Posts}
    </div>
    </section>
  </Layout>);
}

export default IndexPage

export const pageQuery = graphql`
  {
    allSitePage(
      sort: { fields: context___date, order: DESC }
      filter: { context: { date: { ne: null } } }
      limit: 5
    ) {
      edges {
        node {
          id
          path
          context {
            id
            name
            parent
            title
            type
            depth
            date
            excerpt
          }
        }
      }
    }
  }
`


export const frontmatter = {
  title: "Index",
}